.productitem {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    /*padding: 15px;*/
    /*border: 1px solid var(--tg-theme-button-color);*/
}

.product-img__wrp {
    width: 100%;
}

.img {
    display: inline-block;
    width: 100%;
    height: 150px;
    margin: 0 auto;
    background: lightgray;
    object-fit: cover;
    background: var(--tg-theme-button-color);
    border-radius: 5px;
}

.description {
    font-size: 0.8em;
}

.add-btn {
    display: none;
    width: 100%;
    margin-top: 15px;
    text-align: center;
    text-decoration: none;
    background: #000;
}