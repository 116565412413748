.advert {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    width: 100%;
    padding: 10px;
}

.advert img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    background: rgba(0,0,0,.1);
    border-radius: 5px;
}

.advert__name {
    text-align: left;
    font-size: 16px;
    font-weight: 600;
}

.advert__price {
    font-size: 15px;
    font-weight: 600;
}

.advert__callback {
    display: inline-block;
    padding: 8px 15px;
    background: #4ade80;
    color: white;
    border-radius: 3px;
    text-decoration: none;
}

.advert__back {
    display: inline-block;
    padding: 8px 15px;
    background: var(--tg-theme-button-color);
    color: white;
    border-radius: 3px;
    text-decoration: none;
}